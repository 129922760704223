import React, {FC, Suspense, useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Header from "./layout/base/Header";
import Footer from "./layout/base/Footer";
import ImageProtection from "./components/image/ImageProtection";
import 'moment/locale/ar';
import {PopUp} from "./pages/home/components/Modal";

interface Props {
    exhibitions: any[]; // Define the type for exhibitions prop
}
const App : FC<Props> = ({ exhibitions })=> {
    const location = useLocation();
    const [showModal, setShowModal] = useState<boolean>(false); // State to manage modal visibility
    const [dropdownItems, setDropdownItems] = useState<any>(exhibitions);

    useEffect(() => {
        if (dropdownItems.length === 0) {
            setDropdownItems(exhibitions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownItems]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        // Check if the current date is between May 19 and May 27
        const currentDate = new Date();
        const startDate = new Date('2024-05-17');
        const endDate = new Date('2024-05-27');
        if (currentDate >= startDate && currentDate <= endDate) {
            setShowModal(true); // Show the modal
        } else {
            setShowModal(false); // Hide the modal
        }
    }, []);
    return (
        <Suspense>
            <Header showMenu={true} exhibitions={exhibitions}/>
            <ImageProtection>
                <Outlet />
            </ImageProtection>
            <Footer exhibitions={exhibitions}/>

            {showModal && <PopUp />}
        </Suspense>
    )
}

export {App}
