import React, {useEffect} from 'react';

interface ImageProtectionProps {
    children: React.ReactElement;
}

const ImageProtection: React.FC<ImageProtectionProps> = ({children}) => {
    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    return <>{children}</>;
};

export default ImageProtection;
