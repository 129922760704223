import React, {FC, useEffect, useState} from "react";
import {HeaderExhibition} from "./components/show/Hero";
import {GalleryExhibition} from "./components/show/GalleryExhibition";
import {useParams} from "react-router-dom";
import {getExhibition} from "../../core/requests";
import H2Text from "../../components/text/H2Text";
import moment from "moment";
import {SocialMeta} from "../../layout/base/components/social-meta";
import {t} from "i18next";
import Cookies from "js-cookie";
import MoreInfo from "./components/MoreInfo";


const ExhibitionShow: FC = () => {
    const param = useParams();
    const [exhibition, setExhibition] = useState<any>();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    useEffect(() => {
        getExhibition(param.slug, currentLanguageCode).then((response) => {
            setExhibition(response.data.data)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.slug]);


    return (
        <>
            <SocialMeta title={exhibition && exhibition.meta_title}
                        description={exhibition && exhibition.meta_description}/>

            {exhibition &&
                <HeaderExhibition exhibition={exhibition}/>
            }
            {exhibition && exhibition.images && exhibition.images.length > 0 &&
                <GalleryExhibition exhibitionImages={exhibition.images}/>
            }

            <MoreInfo />
        </>
    );
};

export default ExhibitionShow;