import moment from "moment/moment";
import {PageContent} from "../models/PageContent";

export const formatTimeRange = (startTime: string | null, endTime: string | null, locale: string) => {
    const formattedComponents = [];

    if (startTime) {
        formattedComponents.push(moment(startTime, 'HH:mm:ss').locale(locale).format("h:mm A"));
    }

    if (startTime && endTime) {
        formattedComponents.push(' to ');
    }

    if (endTime) {
        formattedComponents.push(moment(endTime, 'HH:mm:ss').locale(locale).format("h:mm A"));
    }

    return formattedComponents.join('');
};

export const filterPageContentBySectionType = (pageContent: PageContent[], sectionType: string) => {
    return pageContent.find((content: PageContent) => content.section_type === sectionType);
};