import React, {FC, useEffect, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";


type Props = {
    showMenu?: any
    exhibitions?: any
};

const languages_2 = [
    {value: 'en', label: "EN", flag: '/assets/images/flags/uk.png', dir: "ltr"},
    {value: 'ar', label: "AR", flag: '/assets/images/flags/france.png', dir: "rtl"},

];

interface OptionType {
    label: string;
    value: string;
    flag?: any;
    dir?: any
}

const Header: FC<Props> = ({showMenu, exhibitions}) => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";


    const location = useLocation();
    const currentRoute = location.pathname;
    const initialSelectedOption = languages_2.find(
        (language) => language.value === currentLanguageCode
    );
    const [selectedOption] = useState<OptionType | null>(
        initialSelectedOption || null
    )
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };

    const [showDropdown, setShowDropdown] = useState(false);


    const toggleDropdown = () => {
        setShowDropdown(prevState => !prevState);
    };


    useEffect(() => {
        document.body.dir = selectedOption?.dir || "ltr";
        document.body.classList.remove("en", "ar");
        document.body.classList.add(selectedOption?.value as string);

    }, [selectedOption, t]);
    return (
        <div className={'fixed-top'}>
            <Navbar expand="xl" className="bg-secondary-color">
                <Container>
                    <Navbar.Brand>
                        <Link className={''} to="/">
                            <img src={"/assets/images/logos/logo.png"} width="250" alt="BFAF" className="logo"/>
                        </Link>
                    </Navbar.Brand>
                    {showMenu &&
                        <>
                            <Navbar.Toggle onClick={toggleNavbar} aria-controls="navbarScroll">
                                {isNavbarExpanded ? (<i className="bi bi-x"/>) : (<i className="bi bi-list"/>)}
                            </Navbar.Toggle>
                            <Navbar.Collapse className={'container justify-content-end'} id="navbarScroll">
                                <Nav className="text-lg-start align-items-center p-lg-0 p-3"
                                     style={{maxHeight: '100px'}} navbarScroll>
                                    <NavLink
                                        className={`nav-link ${(!location.pathname.includes('/exhibitions') && !location.pathname.includes('/about') && !location.pathname.includes('/whats-on') && !location.pathname.includes('/visit') && !location.pathname.includes('/exhibition')  && !location.pathname.includes('/event')) && 'active'}`}
                                        to="/">{t('menu.home')}</NavLink>
                                    <NavLink className={`nav-link ${location.pathname.includes('/event') || location.pathname.includes('/whats-on') ? 'active' : ''}`} to="/whats-on">{t('menu.whatsOn')}  </NavLink>
                                    <NavLink className={`nav-link ${location.pathname.includes('/exhibitions') && 'active'}`} to="/exhibitions">{t('menu.programs')}  </NavLink>


                                    <NavDropdown title={t('menu.exhibitions')}
                                                 className={` ${location.pathname.includes('/exhibition') && !location.pathname.includes('/exhibitions') && 'active'}`}
                                                 id="navbarScrollingDropdown">
                                        {exhibitions && exhibitions.length > 0 && exhibitions.map((item: any) => (
                                            <NavDropdown.Item key={item.id} href={`/exhibition/${item.slug}`}
                                                              className={`${location.pathname.includes(item.slug) ? 'active' : ''}`}>
                                                {item.name}
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>

                                    <NavLink
                                        className={`nav-link pe-lg-3  ${location.pathname.includes('/about') && 'active'}`}
                                        to="/about">{t('menu.about')}</NavLink>
                                    <NavLink
                                        className={`nav-link  ${location.pathname.includes('/visit') ? 'btn btn-primary inverse text-light lt-sp-3 disabled' : 'btn btn-primary text-light lt-sp-3'}`}
                                        to="/visit">{t('menu.visit')}  </NavLink>

                                    <div className={`dropdown f-regular `}>
                                        <button
                                            className="btn btn-link dropdown-toggle text-primary-color text-no-decoration"
                                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                            aria-expanded="false" onClick={toggleDropdown}>
                                            <span> <span className={'pt-1'}>{selectedOption?.label}</span> </span>
                                        </button>
                                        {showDropdown && (
                                            <ul className="dropdown-menu minW d-block"
                                                aria-labelledby="dropdownMenuButton1">
                                                {languages_2.map(({value, label, flag}) => (
                                                    <li key={value} className={'text-center'}>
                                                        <a href={`/${value}${currentRoute.replace(/^\/(ar|en)\//, '/')}`}
                                                           className={'dropdown-item fs-14 text-center'}>
                                                            {label}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </>
                    }

                </Container>
            </Navbar>
        </div>
    )
}
export default Header;
