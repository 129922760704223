import React, {FC, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {t} from "i18next";
import Cookies from "js-cookie";


const PopUp: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const currentLanguageCode = Cookies.get("i18next") || "en";

    function openModal() {
        setIsModalOpen(true);

    }

    const closeModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            openModal()
        }, 2000)
    }, []);
    return (
        <div>
            <Modal className="modal " size="lg" show={isModalOpen} onHide={closeModal} centered>
                <div className="text-center bg-white  main-text">
                    <Modal.Header className="border-bottom-0" closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div className={`${currentLanguageCode === 'en' ? 'text-start' : 'text-end'} pb-5 bg-white main-text g-l`}
                             dangerouslySetInnerHTML={{__html: t('popUp')}}>
                        </div>

                    </Modal.Body>
                </div>


            </Modal>
        </div>

    );
}
export {PopUp};

